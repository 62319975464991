import { Component, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '@graat-hp/shared/util';
import { isScullyGenerated } from '@scullyio/ng-lib';
import { environment } from '../environments/environment';
import { scrollIntoFragment } from './functions/scroll-into-fragment';

const setTagScript = () => `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${environment.gtagId}');`;

@Component({
  selector: 'graat-hp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private title: Title,
    private router: Router,
    private zone: NgZone,
    private activateRouter: ActivatedRoute,
    private script: ScriptLoaderService
  ) {
    if (isScullyGenerated()) {
      this.script.script(
        `https://www.googletagmanager.com/gtag/js?id=${environment.gtagId}`,
        { async: true }
      );
      this.script.text(setTagScript());

      if (environment.production) {
        this.script.scriptWithId(
          'hs-script-loader',
          '//js.hs-scripts.com/46721470.js',
          { async: true, defer: true }
        );
      }
    }
    this.activateRouter.fragment.subscribe((fragment) => {
      if (fragment) {
        scrollIntoFragment(fragment);
      }
    });
  }
}
